
import { VDPopupListboxClass } from "@/components/pickers/VDPopupListbox";
import VDPopupListbox from "@/components/pickers/VDPopupListbox.vue";
import { RoleType, ApplicationType } from "@/graphql/types";
import GroupedItems from "@/models/GroupedItems";
import { defineComponent, PropType, ref, computed } from "vue";

export default defineComponent({
  components: {
    VDPopupListbox,
  },
  emits: ["addedRole"],
  props: {
    addedRoles: {
      type: Array as PropType<RoleType[]>,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    application: {
      type: Object as PropType<ApplicationType>,
      default: () => ({}),
    },
  },
  setup(props) {
    // VDOM
    const listbox = ref<VDPopupListboxClass>();
    // Refs
    const isEnabled = ref(false);

    const groupedRoles = computed(() => {
      return [
        {
          name: props.application.name,
          items: props.application.roles,
        },
      ];
    });

    return {
      groupedRoles,
      listbox,
      toggle: (event: Event) => {
        isEnabled.value = true;
        listbox.value?.toggle(event);
      },
    };
  },
});
